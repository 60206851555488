body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
  background-color: #008080;
  background-repeat: no-repeat;
  background-position-x: center;
  background-position-y: 40px;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); 
  -webkit-tap-highlight-color: transparent; 
}

button { 
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

a {
  color: black;
  text-decoration: none;
}

iframe {
  width: 83%;
  height: 260px;
  border-top: 2px solid white;
  border-right: 2px solid #222;
  border-bottom: 2px solid #222;
  border-left: 2px solid white;
}

.icon-list{
  display: flex;
  align-items: flex-start;
  height: 90%;
  flex-direction: column;
  margin-left: 20px;
  margin-top: 20px;
  position: absolute;
  flex-wrap: wrap;
  z-index: -1;
}
.icon {
  display: block;
  text-align: center;
  width: 100px;
}

.icon:active {
  /* border: 1px dashed rgba(133, 133, 133, 0.486); border moves box!!! */
  background-color: rgba(80, 80, 80, 0.24);
}

.icon > p {
  margin-top: 5px;
  color: white;
  font-size: 1rem;
}

.dock {
  background-color: #c0c0c0;
  height: 38px;
  width: 100%;
  position: fixed;
  bottom: 0;
  border-top: 2px solid #efefef;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index:2;
}

.start-button {
  display: flex;
  justify-items: space-evenly;
  align-items: center;
  color: black;
  /* line-height: 25px; */
  font-weight: bold;
  height: 30px;
  background-color: #c0c0c0;
  border-top: 2px solid white;
  border-right: 2px solid #222;
  border-bottom: 2px solid #222;
  border-left: 2px solid white;
}

.start-button:active {
  border-bottom: 2px solid white;
  border-left: 2px solid #222;
  border-top: 2px solid #222;
  border-right: 2px solid white;
}

.start-button > img {
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.start-button > span {
  font-size: 1rem;
  font-weight: 900;
}

.menu-button:hover {
  background-color: rgb(160, 160, 160);
}

.menu-button:active {
  background-color: rgba(17, 9, 134, 0.808);
}

.menu-button-disabled {
  color: grey;
}


.start-menu {
  position: absolute;
  bottom: 38px;
  background: #c0c0c0;
  border-top: 2px solid white;
  border-right: 2px solid #222;
  border-bottom: 2px solid #222;
  border-left: 2px solid white;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  font-size: 14px;
  display: block;
  z-index: 1;
}

.start-menu .title-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 37px;
  height: 100%;
  background: linear-gradient(#000184 60%, #0f80cd);
}


.start-menu .title-container .title {
  position: absolute;
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  color: white;
  bottom: 100px;
  left: -80px;
  font-size: 25px;
  font-weight: bold;
}

.start-menu .title-container .title span {
  font-weight: 100;
}
.start-menu hr {
  margin: 0 4px 0 40px;
}
.start-menu ul {
  margin: 0;
  padding: 0;
}
.start-menu ul li {
  padding: 18px 100px 18px 100px;
  list-style: none;
  background-repeat: no-repeat;
  background-position: 48px center;
  background-size: 33px;
}
.start-menu ul li.windows-update {
  background-image: url(https://68.media.tumblr.com/f33cc852028056f5c3f41daa4ba007c3/tumblr_odq9qssnET1vgs7gco1_75sq.png);
}

.window-container {
  position: relative;
  text-align: center;
  width: 580px;
  margin: auto;
  padding-top: 20px;
  z-index: 1;
  /* overflow-y: scroll; */
  max-height: 80vh;

}

.window {
  display: flex;
  flex-direction: column;
  border-top: 2px solid white;
  border-right: 2px solid #222;
  border-bottom: 2px solid #222;
  border-left: 2px solid white;
  background: #c0c0c0;
  /* background-repeat: no-repeat; */
  /* background-size: 60px; */
  /* background-position: 15px 55px; */
  /* display: inline-block; */
  position: relative;
  flex-wrap:wrap;
  
  

}

.window-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #000184, #0f80cd);
  padding: 4px 7px 5px;
  color: white;
  font-size: 15px;
  margin-right: 2px;
  text-align: left;
  font-weight: bold;
}
.window-title:hover {
  cursor: move;
}


.window-body {
  padding: 10px 10px 10px 10px;
  font-size: 14px;
  text-align: left;
}

.custom-window-body {
  padding: 0px 5px 0px 0px;
  font-size: 14px;
  text-align: left;
}

.window-body > h3 {
  text-decoration: underline;
}

.window-body > img {
  float: left;
  width: 40%;
  margin: 10px 10px 10px 0px;
  border-right: 2px solid rgb(27, 27, 27);
  border-bottom: 2px solid rgb(27, 27, 27);
  border-left: 2px solid rgb(120, 120, 120);
  border-top: 2px solid rgb(120, 120, 120);
}


.close-button {
  background: #c0c0c0;
  border-right: black 2px solid;
  border-bottom: black 2px solid;
  border-left: rgb(255, 255, 255) 2px solid;
  border-top: rgb(255, 255, 255) 2px solid;
  text-align: center;
  color: black;
  width: 20px;
  height: 17px;
}

.close-button:active {
  border-left: black 2px solid;
  border-top: black 2px solid;
  border-right: rgb(255, 255, 255) 2px solid;
  border-bottom: rgb(255, 255, 255) 2px solid;

}

.custom-iframe {
  width:100%;
}

.window-headline {
  text-decoration: black underline;
  margin-bottom: 0px;
}

.window-buttons {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 10px;
}

.window-buttons > div {
  text-align: center;
  width: 100px;
  height: 20px;
  border-right: black 2px solid;
  border-bottom: black 2px solid;
  border-left: rgb(255, 255, 255) 2px solid;
  border-top: rgb(255, 255, 255) 2px solid;
}

.window-buttons > div:active {
  border-top: black 2px solid;
  border-left: black 2px solid;
  border-right: rgb(255, 255, 255) 2px solid;
  border-bottom: rgb(255, 255, 255) 2px solid;
}

.dock-time {
  border-top: 2px solid #666;
  border-right: 2px solid white;
  border-bottom: 2px solid white;
  border-left: 2px solid #666;
  /* float: right; */
  width: 30px;
  margin-right: 8px;
  padding: 5px 15px 5px 35px;
  font-size: 14px;
  background: url(https://upload.wikimedia.org/wikipedia/commons/thumb/6/6c/Gnome-audio-volume-high.svg/120px-Gnome-audio-volume-high.svg.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 5px center;
}

.tech-list {
  display: flex;
  background-color: white;
  justify-content: space-evenly;
  border-left: black 2px solid;
  border-top: black 2px solid;
  border-right: rgb(255, 255, 255) 2px solid;
  border-bottom: rgb(255, 255, 255) 2px solid;
  align-items: flex-start;
  flex-direction: row;
  margin-top: 10px;
  flex-wrap: wrap;
}

.tech-icon {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  margin: 5px;
  border-top: 1px solid rgb(200, 200, 200);
  border-left: 1px solid rgb(200, 200, 200);
  border-right: 2px solid rgb(120, 120, 120);
  border-bottom: 2px solid rgb(120, 120, 120);
  height: 80px;
  width: 80px;
  padding: 5px;
}

.tech-icon:active {
  border-bottom: 1px solid rgb(200, 200, 200);
  border-right: 1px solid rgb(200, 200, 200);
  border-left: 2px solid rgb(120, 120, 120);
  border-top: 2px solid rgb(120, 120, 120);
}

.tech-icon > img {
  text-align: center;
  /* width: 48px; */
  height: 64%;
}

.tech-icon > p {
  margin-top: 0;
  margin-bottom: 0;
}

.tech > p {
  margin-top: 5px;
  color: white;
  font-size: 1rem;
}

.project-menu {
  margin-top: 6px;
  background: #c0c0c0;
  padding-bottom: 20px;
}

.project-header {
  display: flex;
  background-color: #c0c0c0;
}

.project {
  border-right: black 1px solid;
  border-left: rgb(255, 255, 255) 1px solid;
  border-top: rgb(255, 255, 255) 1px solid;
  padding: 5px;
}

.project-body {
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-top: 1px solid white;
  border-left: 1px solid white;
  padding: 5px;
  padding-top: 10px;
}

.project-info {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: scroll;
}

.project-demo {
  display: flex;
  flex-direction: column;
  align-content: center;
}

.mini-project {
  display: flex;
  flex-direction: column;
  align-content: center;
  font-size: 1.2rem;
  font-size: 2rem;
}


.project-title {
  font-size: 2rem;
}

.project-subtitle {
  font-size: 1.2rem;
}

.project-data {
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.project-table {
  background-color: white;
  width: 100%;
}

tbody {
  background-color: white;
  width: 100%;
}

td > img {
  width: 30px;
}


.tech-used {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: space-around;
  border: 1px solid black;
  background-color: white;
  height: 160px;
  /* width: 120px; */
  margin-left: 20px;
  padding: 5px;
}

.active {
  position:relative;
  z-index: 1;
  padding-bottom: 3 px;
  background-color: #c0c0c0;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

h1 {
  margin: 0;
}

h3 {
  margin: 0;
}


.start-menu ul li.programs {
  background-image: url(https://66.media.tumblr.com/49ed6c0ebac47753e08e85c230d97d03/tumblr_odqaag4zd41vgs7gco2_75sq.png);
}
.start-menu ul li.favorites {
  background-image: url(https://66.media.tumblr.com/2d4a1593f0a13df6d507304708e11a7f/tumblr_odqaag4zd41vgs7gco1_75sq.png);
}
.start-menu ul li.documents {
  background-image: url(https://66.media.tumblr.com/6ee194172c15584561b951ff258d9d1d/tumblr_odqaag4zd41vgs7gco3_75sq.png);
}
.start-menu ul li.settings {
  background-image: url(https://66.media.tumblr.com/83833fe1b6ac3b482a89ff02aad3ed15/tumblr_odqaag4zd41vgs7gco9_75sq.png);
}
.start-menu ul li.find {
  background-image: url(https://68.media.tumblr.com/d4f271116e0f67b79c64f2a1aac6488e/tumblr_odqaag4zd41vgs7gco8_75sq.png);
}
.start-menu ul li.help {
  background-image: url(https://67.media.tumblr.com/f269281536842a64ec1a35479ca8fdd3/tumblr_odqaag4zd41vgs7gco5_75sq.png);
}
.start-menu ul li.run {
  background-image: url(https://66.media.tumblr.com/4ff9a932e9ba9b4736ddc63fae12b4fb/tumblr_odqay8HODs1vgs7gco1_75sq.png);
}
.start-menu ul li.log-off {
  background-image: url(https://66.media.tumblr.com/1439f4d5c4bd20dcf6b98916a7cfb24e/tumblr_odqaag4zd41vgs7gco6_75sq.png);
}
.start-menu ul li.shut-down {
  background-image: url(https://66.media.tumblr.com/1f29d811f6e2bb3f8fbe912057fc6e58/tumblr_odqaag4zd41vgs7gco10_75sq.png);
}



@media (max-width: 680px) {

  .window-container{
    width: 95vw;
  }

  .window-body > img {
    width: 35%;
  }

  .window-buttons > div {
    width: 80px;
  }

  p {
    font-size: 12px;
  }

  body {
    background-image: none;
  }

  iframe {
    height: 200px;
  }

  .tech-list {
    justify-content: space-around;
    flex-direction: column;
  }

  .tech-icon{
    flex-direction: row-reverse;
    font-size: 12px;
    height: 20px;
    width: 90%;
    padding-bottom: 1px;
    padding-top: 1px;
    margin: 0 0 0 0 ;
    border: none;
  }

  .tech-icon:active {
    border: none;
    background-color: rgb(3, 0, 161);
    color: white;
  }

  .tech-icon > img {
    text-align: center;
    height: 100%;
    width: auto;
  }

  .tech-icon > p {
    margin-right: .1rem;
  }

}

/* winamp looks better without 98 style borders */
#main-window, #equalizer-window, #playlist-window {
  border: none;
}
